
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {loadPaginatedEstore, getFullName, proceedConfirmTransferProduct}  from "@/api/estore";
import { string } from "yup/lib/locale";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EstoreTabNavigator from "@/components/cards/EstoreTabNavigator.vue";
import ECShopAdvisory from  "@/views/ECShopAdvisory.vue";


export default defineComponent({
  name: "reorder",
  components: {
    EstoreTabNavigator,
    ECShopAdvisory
  },
  data() {
    return { 
      list : [],
      pagination: {
        total: 0,
        per_page: 10,    // required
        current_page: 1, // required
        last_page: 0,    // required
        from: 1,
        to: 12
      },
      isBCOAccount : false,
      current_usid : "",
      store_count : {
        type: string,
        default : []
      },
			tousid : {
        type: string,
        default : []
      },
			pos : {
        type: string,
        default : []
      },
      isprocessing : {
        type: string,
        default : []
      },
			retro : "0",
      loading: true,
      destination_usid : "",
      destination_fullname: "",
      transferCart : [] as any,
      cartRow : {prodcode : "", prodname : "", qty : 0},
      enabledTransferButton : false,
      isShowConfirmation : false,
    }
  },
  mounted(){
      this.loadPaginatedEstore();
  },
  methods : {
    async getTransferProductCartList(){
      console.log(this.transferCart);
      var ctr = 0;
      var transferCartLength = this.transferCart.length;
      var prodcode_list = '';
  		var prod_qty_list = '';
  		var prod_name_list = '';

      for(ctr = 0; ctr<transferCartLength; ctr++){
        const temp = this.transferCart[ctr];
        prodcode_list = prodcode_list + temp.prodcode + '#' ;
  			prod_qty_list = prod_qty_list + temp.quantity + '#' ;
  			prod_name_list = prod_name_list + temp.prodname + '#' ;
      }
      
      return prodcode_list + 'VVAANN' + prod_qty_list + 'VVAANN' + prod_name_list;
    }, 
    async proceedConfirmTransferProduct(){
      var prodcode_prodqty_lit =  await this.getTransferProductCartList();
      const params = {
          "cartdetails" : prodcode_prodqty_lit,
				  "toUsid" : this.destination_usid,
        };
      const response = await proceedConfirmTransferProduct(params);
      if(response != null){
        if (response.data.result == "SUCCESS") {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
            
          }); 
          window.location.reload();
        } else if(response.data.result == "FAILED"){
          
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
            
          }); 
          this.cancelConfirmTransferProduct();
        } 
      }

    },
    async showTransferProductConfirmation(){
      this.isShowConfirmation = true;
    },
    async cancelConfirmTransferProduct(){
      this.isShowConfirmation = false;
    },
    async enableDisableTransferButton(){
      var len = this.transferCart.length;
      if(len > 0 && this.destination_fullname != "" && this.destination_fullname != "Invalid Destination USID."){
          this.enabledTransferButton = true;
      } else {
        this.enabledTransferButton = false;
        this.isShowConfirmation = false;
      }
    },
    async removeTableRow(pcode){
      var arrayIndexOfTheExistingPCode = -1;
      var ctr = 0;
      var transferCartLength = this.transferCart.length;

      for(ctr = 0; ctr<transferCartLength; ctr++){
        const temp = this.transferCart[ctr];
        if(temp.prodcode == pcode){
          arrayIndexOfTheExistingPCode = ctr;
          this.transferCart.splice(ctr, 1);
        }
      }

      this.enableDisableTransferButton();
    },
    async addTableRow(pcode, pname, index, balance){ 
      let qty = this.store_count[index];
      var ctr = 0;
      var alreadyExists = 0;
      var arrayIndexOfTheExistingPCode = -1;
      var transferCartLength = this.transferCart.length;

      
      if(qty != 0 && (qty <= parseInt(balance))){

        for(ctr = 0; ctr<transferCartLength; ctr++){
          const temp = this.transferCart[ctr];
          if(temp.prodcode == pcode){
            alreadyExists = 1;
            arrayIndexOfTheExistingPCode = ctr;
          }
        }

        if(alreadyExists == 1){
          const existingPCode = this.transferCart[arrayIndexOfTheExistingPCode];
          existingPCode.quantity = parseInt(existingPCode.quantity) + parseInt(qty);
        } else {
          this.cartRow = {prodcode : pcode, prodname : pname, quantity : qty} as any;
          this.transferCart.push(this.cartRow);
        }
        
        this.enableDisableTransferButton();
      }
    },
    async setDefaultValues(listSize:number){
      let ctr = 0;
      for(ctr = 0; ctr < listSize; ctr++){
          this.store_count[ctr] = "0";
          this.pos[ctr] = "L";
          this.tousid[ctr] = this.current_usid;
          this.isprocessing[ctr] = "0";
      }
    },

    async loadPaginatedEstore(){ 
      this.loading = true;
      const options = {
        params: {
          page: 1,
          /* additional parameters */
        }
      };
      const response = await loadPaginatedEstore();
      this.list = response.data.pageList;
      this.pagination = response.data.pagination;
      this.isBCOAccount = response.data.isBCOAccount;
      this.current_usid = String(localStorage.getItem("ec_current_usid"));
      let listSize = this.list.length;
      this.setDefaultValues(listSize);
      this.loading = false;

    },
    async getDestinationFullname(){
      let params = {"toUsid" : this.destination_usid};
      const response = await getFullName(params);
      this.destination_fullname = response.data.fullname;
      this.enableDisableTransferButton();
    },
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("estoreTab") || "0");
      setCurrentPageTitle("Estore");

    });
    
  }
});
